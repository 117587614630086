<div class="modal-header">
  <div class="title-row py-0">
    <div class="inner-page-title">Change Password</div>
  </div>
  <button
    type="button"
    class="pull-right btn-close-popup"
    aria-label="Close"
    (click)="closeModal()"
  >
    <i class="ic-close"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-8 mx-auto">
      <div class="studnt-dashbrd-rgt-sec school-dashbrd-rgt-sec">
        <div class="home-reg-wrapper mr-0">
          <form
            name="changePasswordForm"
            [formGroup]="changePasswordForm"
            (ngSubmit)="changePassword(changePasswordForm)"
            autocomplete="off"
            novalidate
          >
            <div class="home-reg-form">
              <div class="benefy-cmn-frm-sec">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="oldPassword">Old Password</label>
                      <input
                        type="password"
                        id="oldPassword"
                        class="form-control"
                        placeholder=" "
                        formControlName="oldPassword"
                        required
                      />
                      <div
                        class="validation-error"
                        *ngIf="
                          submitted &&
                          changePasswordForm.get('oldPassword').errors &&
                          changePasswordForm.get('oldPassword').errors.required
                        "
                      >
                        Old Password is required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="newPassword">New Password</label>
                      <input
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        class="form-control"
                        placeholder=" "
                        formControlName="newPassword"
                        required
                      />
                      <div
                        class="validation-error"
                        *ngIf="
                          submitted &&
                          changePasswordForm.get('newPassword').errors &&
                          changePasswordForm.get('newPassword').errors.required
                        "
                      >
                        New Password is required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="confirmNewPassword"
                        >Confirm New Password</label
                      >
                      <input
                        type="password"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        class="form-control"
                        placeholder=" "
                        formControlName="confirmNewPassword"
                        required
                      />
                      <div
                        class="validation-error"
                        *ngIf="
                          submitted &&
                          changePasswordForm.get('confirmNewPassword').errors &&
                          changePasswordForm.get('confirmNewPassword').errors
                            .required
                        "
                      >
                        Confirm New Password is required
                      </div>
                      <div
                        class="validation-error"
                        *ngIf="
                          submitted &&
                          changePasswordForm.get('newPassword') &&
                          changePasswordForm.get('confirmNewPassword') &&
                          changePasswordForm.get('newPassword').value !==
                            changePasswordForm.get('confirmNewPassword').value
                        "
                      >
                        New Password and Confirm New Password Miss match
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="benefy-cmn-frm-sec brdr-non">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="save-btm-btn">
                      <button class="btn-cmn btn-green" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
