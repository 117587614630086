<div class="container-fluid px-0" [ngClass]="{ mini: menu.isMini }">
  <!-- Left Navbar start -->
  <app-sidebar></app-sidebar>
  <!-- Left Navbar end -->
  <div class="right-main-content">
    <!-- Header start -->
    <app-header></app-header>
    <!-- Header end -->
    <div class="right-main-content-inner">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>